/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, {
    useEffect,
    useState,
    useContext
} from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useHistory, useParams } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';
import './test.css';
import { getEmbedInfo } from '../../api/user';
import { ContainerContext } from '../../app/App';
import { useStyles } from '../../app/App'
import { CircularProgress, Grid, RACButton } from '@rentacenter/racstrap';
import { CustomPropInterface } from '../..';
import Modal from 'react-modal';
import clsx from "clsx";
// declare global {
//     interface Window {
//         FB: any;
//     }
// }
// window.report = ''
export const PBIReports = (props: any) => {
    // eslint-disable-next-line no-debugger
    debugger
    const [loader, setloader] = useState(false);
    const [embedInfoResponse, setembedInfoResponse] = useState<any>('')
    const containerData: any = useContext(ContainerContext) as CustomPropInterface;
    const classes = useStyles();

    const history: any = useHistory();
    const { reportName }: any = useParams();

    const printHTML = async (html: any) => {
        console.log('html Contents', html);
        if (html !== undefined && html !== null) {
            const divContents = html.innerHTML;
            console.log('div Contents', divContents);
            const a: any = window.open('', '', 'height=1000, width=1200');
            a.document.write('<html>');
            a.document.write(divContents);
            a.document.write('</html>');
            a.document.close();
            console.log('opened window', a);
            console.log('opened window document', a.document);
            await a.print();
        }
    };

    useEffect(() => {
        try {
            console.log('containerData', containerData);
            console.log('GetEmailId', containerData.GetEmailId());
            console.log('GetEmployeeId', containerData.GetEmployeeId());

            // eslint-disable-next-line no-debugger
            debugger
            const reportInfo = props && props.reportDetails && props.reportDetails.storeProfileResponse?.configDetails[0]?.configDetails.find((el: any) => el.paramKeyName == reportName)
            console.log('reportInfo', reportInfo);
            const apiCall = async () => {
                if (reportInfo.paramValue != '0') {
                    setloader(true);
                    const embedInfo = await getEmbedInfo(reportInfo.paramValue, containerData.GetEmployeeId());
                    setloader(false);
                    setembedInfoResponse(embedInfo);
                } else {
                    history.push({ pathname: reportInfo.url })
                }
            }
            apiCall()

        } catch (e: any) {
            setloader(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reportDetails, reportName])
    return (
        <div
            style={{
                marginTop: '50px',
            }}
        >
            <Modal
                isOpen={loader}
                className={classes.loaderStyle}
                id="openpopup"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid>
                    <Grid>
                        <Grid className={classes.RACLoaderPage}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
            {' '}
            {embedInfoResponse?.accessToken ? (
                <div>
                    {' '}
                    <PowerBIEmbed
                        embedConfig={{
                            type: 'report', // Supported types: report, dashboard, tile, visual and qna              id: '524a1940-b34d-41e5-80b8-65d251063ec4',
                            id: `${embedInfoResponse?.embedUrl[0]?.reportId}`,
                            embedUrl:
                                `${embedInfoResponse?.embedUrl[0]?.embedUrl}&rp:UserID=${containerData?.GetEmployeeId()}`,
                            accessToken: `${embedInfoResponse?.accessToken}`,
                            tokenType: models.TokenType.Embed,
                            settings: {
                                commands: {
                                    parameterPanel: {
                                        enabled: true,
                                        expanded: true,
                                    },
                                },
                                visualSettings: {
                                    visualHeaders: [
                                        {
                                            settings: {
                                                visible: false
                                            }
                                        }
                                    ]
                                },
                                panes: {
                                    pageNavigation: {
                                        visible: false
                                    }
                                }
                            },
                        }}
                        eventHandlers={
                            new Map([
                                [
                                    'loaded',
                                    function () {
                                        console.log('Report loaded');
                                    },
                                ],
                                [
                                    'rendered',
                                    function () {
                                        console.log('Report rendered');
                                    },
                                ],
                                [
                                    'error',
                                    function (event: any) {
                                        console.log(event?.detail);
                                    },
                                ],
                            ])
                        }
                        cssClassName={'report-style-class'}
                        getEmbeddedComponent={(embeddedReport: any) => {
                            console.log('embedded report', embeddedReport);
                            (window as any).report = embeddedReport;
                        }}
                    />{' '}
                </div>
            ) : null}
            <Grid
                item
                classes={{
                    root: clsx(classes.customerToolbar, classes.fixedBottom),
                }}
                style={{ zIndex: 0 }}
            >
                <Grid item className={classes.floatLeft}>
                    <RACButton
                        className={classes.spacerMR2}
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            history.push({ pathname: `/reporting` });
                        }}
                    >
                        Cancel
                    </RACButton>

                    {/* Commenting the Print and Print Div button */}
                    {/* <RACButton
                        className={classes.spacerMR2}
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={async () => {
                            await (window as any).report.print();
                        }}
                    >
                        Print
                    </RACButton>
                    <RACButton
                        className={classes.spacerMR2}
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={async () => {
                            const reportFrame = document.querySelectorAll('[data-testid=react-renderer-report-container]');
                            console.log('div print', reportFrame);
                            await printHTML(reportFrame[0]);
                        }}
                    >
                        Print Div
                    </RACButton> */}
                </Grid>
            </Grid>
        </div>
    );
};
