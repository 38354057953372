/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React, { createContext } from 'react';
import { Router } from 'react-router';
import { RACThemeProvider, makeStyles, createGenerateClassName, StylesProvider } from '@rentacenter/racstrap';
import clsx from 'clsx';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const History = require('history');
import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import { InContainerAuthProvider } from '../context/auth/InContainerAuthProvider';
import { StandaloneAuthProvider } from '../context/auth/StandaloneAuthProvider';
import { AuthGuard } from '../components/AuthGuard/AuthGuard';
import { CustomPropInterface } from '../../src/index';
interface Props {
  history: typeof History;
  customProps?: CustomPropInterface;
}

export const useStyles = makeStyles((theme:any) => ({
  container: {
    width: '100%'
  },
  loaderStyle: {
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    textAlign: "center",
  },
  RACLoaderPage: {
    textAlign: "center",
    marginTop: "250px",
    fontSize: "16px",
    marginLeft: "30px",
    marginRight: "30px",
    outline: "none",
  },
  customerToolbar: {
    position: "fixed",
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1030,
    backgroundColor: `white`,
    boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
    paddingLeft: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(16),
    paddingTop: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(16),
  },
  fixedBottom: {
    position: "fixed",
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1030,
    backgroundColor: `white`,
    boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
    padding: "1rem",
  },
  floatLeft: {
    float: "left",
  },
  spacerMR2: {
    marginRight: theme.typography.pxToRem(8),
  },
}));

const generateClassName = createGenerateClassName({
  //disableGlobal: true,
  //productionPrefix: 'racprodprefix',
  seed: 'RAC-REPORTING-',
});
export const appTestId = 'appTestId';

export const ContainerContext = createContext<CustomPropInterface | undefined>(
  undefined
);

export const App = ({ history, customProps }: Props) => {
  console.log('inside reporting..');
  const enableLogs = window.sessionStorage.getItem('enableLogs');

  if (enableLogs != '1') {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.log = function () { };
  }
  const { isRenderedByContainer } = window;
  const classes = useStyles();

  return (
    <div data-testid={appTestId}>
      <StylesProvider generateClassName={generateClassName}>
      <RACThemeProvider>
        <ContainerContext.Provider value={customProps}>
        <div className={clsx(classes.container)}>
          <ErrorBoundary>
            <Router history={history}>
              {isRenderedByContainer ? (
                <InContainerAuthProvider>
                  <AuthGuard />
                </InContainerAuthProvider>
              ) : (
                <StandaloneAuthProvider>
                  <AuthGuard />
                </StandaloneAuthProvider>
              )}
            </Router>
          </ErrorBoundary>
        </div>
        </ContainerContext.Provider>
      </RACThemeProvider>
    </StylesProvider>
    </div>
  );
};
