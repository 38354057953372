/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

// import { AppRoute } from '../config/route-config';
import { UserProvider } from '../context/user/UserProvider';
import { PermissionsProvider } from '../context/permission/PermissionsProvider';
import { UserFetcher } from '../components/UserFetcher/UserFetcher';
// import { Placeholder1 } from '../components/PlaceHolder1/PlaceHolder1';
// import { Placeholder2 } from '../components/PlaceHolder2/PlaceHolder2';
import ReportDetails from '../components/reports/ReportDetails';
import { storeConfigCall } from '../api/user';
import Modal from 'react-modal';
import { useStyles } from './App'
import { CircularProgress, Grid } from '@rentacenter/racstrap';
import { PBIReports } from '../components/reports/PBIReports';
export const routesTestId = 'routesTestId';

export const Routes = () => {
  const [reportDetail, setReportDetail] = useState<any>([]);
  const [loader, setloader] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    // eslint-disable-next-line no-debugger
    debugger;
    const pageLoad: any = async () => {
      const payload: any = {
        storeNumbers: [sessionStorage.getItem('storeNumber')],
        paramKeyNames: [
          'InventoryRecapReport',
          'InventoryInAuditTrialReport',
          'InventoryOutAuditTrialReport',
          'ItemBeingServicedReport',
          'OnRentInventoryReport',
          'PettyCashAnalysisDetailReport',
          'PettyCashAnalysisSummaryReport',
          'ReceiptAuditTrailReport',
          'SalesPersonRecapReport',
          'SkipStolenReport',
          'DailyActivityPlanner',
          'ExceptionAuditTrial',
          'IdleInventoryReport',
          'TransactionAuditTrialReport',
          'DailyReport',
        ],
      };
      setloader(true);
      const getReportDetails: any = await storeConfigCall(payload);
      setReportDetail(getReportDetails);
      setloader(false);
    };
    pageLoad();
  }, []);
  return (
    <>
      <Modal
        isOpen={loader}
        className={classes.loaderStyle}
        id="openpopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid>
          <Grid>
            <Grid className={classes.RACLoaderPage}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
  <UserProvider>
    <PermissionsProvider>
      <UserFetcher />
      <div data-testid={routesTestId}>
        <Switch>
          <Route exact path='/reporting/report'>
            <ReportDetails />
          </Route>
          <Route
            exact
            path="/reporting"
          >
          <ReportDetails reportDetails={reportDetail || []}/>
          </Route>
          <Route
            exact
            path="/reporting/:reportName"
          >
          <PBIReports reportDetails={reportDetail || []} />
          </Route>
        </Switch>
      </div>
    </PermissionsProvider>
  </UserProvider>
    </>)
};
